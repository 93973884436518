import { useEffect } from 'react';

const Redirector = () => {
  useEffect(() => {
    const userLocale = navigator.language || navigator.userLanguage || 'en';
    const simplifiedLocale = userLocale.split('-')[0];

    const localeToURL = {
      en: 'https://en.pwncat.com',
      es: 'https://es.pwncat.com',
      //ca: 'https://ca.pwncat.com',
      // Add more locales and URLs as needed
    };

    const destinationURL = localeToURL[simplifiedLocale] || localeToURL['en'];

    window.location.replace(destinationURL);
  }, []);

  return null; // Redirector component doesn't render anything
};

export default Redirector;
