import React from 'react';
import Redirector from './components/Redirector'; // Import the Redirector component

// Vercel 
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div>
      {/* Your main application content */}
      <h1>Redirector App powered by PWNCAT!</h1>
      {/* Include the Redirector component */}
      <Redirector />
      <Analytics mode={'production'} />;
    </div>
  );
}

export default App;
